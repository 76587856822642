

.Footer_direct_link {
    width: 25%;
    
}

.footer_links {
    width: 100%;
}

.single-des-link {
    margin-right: 5px;
    font-size: 12px;
    transition: all 0.3s;
}

.single-des-link:hover {
    color: #09a359;
}

@media (width<=640px) {
    .highlights-para {
        font-size: 10px;
    }

    .highlight,
    .highlights-para {
        text-align: center;
    }

    .responsive_center {
        justify-content: center;
        width: 100%;
    }
}

@media (width<=500px) {
    .footer_links {
        flex-wrap: wrap;
    }

    .Footer_direct_link {
        width: 50%;
    }
}