.package_multi_select_comp {
    label {
        font-size: 14px;
    }

    .selected-data {
        border: 1px solid #ccc;
        padding: 0 10px;
        border-radius: 4px;
        display: block;
        margin-top: 5px;
        position: relative;

        input {
            width: 100%;
            border: 0;
            background-color: transparent;

            &:focus {
                outline: none;
            }
        }

        .selected-value {
            background-color: #aabff947;
            border-radius: 4px;
            padding: 2px 5px 5px;
            display: flex;
            gap: 3px;
            align-items: center;
        }
    }

    .selected-dropdown {
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        padding: 10px 0;
        transition: 0.2s;
        position: absolute;
        width: 150px;
        width: 100%;
        left: -0.2px;
        border: 1px solid #ccc;
        border-top: 0;
        max-height: 200px;
        /* Set a max height */
        overflow-y: auto;
        /* Enables vertical scrolling */
        z-index: 10;
        text-align: start;

        span {
            // color: #333;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            /* width: 100%; */
            display: block;

            &:hover {
                background-color: #3968ed;
                color: white;
            }
        }
    }
}