.pointsRememberTest ul li {
  display: block;
}
.pointsRememberTest ul li {
  margin-top: 10px;
}

.singlePackageTabShowBullets ul li {
  display: list-item;
  list-style: disc;
  /* margin-left: 20px; */
}

.singlePackageTabShowBullets strong {
  padding-top: 10px;
  /* background: red; */
  display: block;
}
