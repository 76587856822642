.traveller_details_popup {
  width: 70%;
  background-color: white;
  position: fixed;
  z-index: 998;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.traveller_details_popup .traveller_details_popup_heading h3 {
  font-size: 20px;
  color: #9b9b9b;
}

.traveller_adult_main h2 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin: 20px 0;
}

.traveller_adult_main h2 sub {
  font-size: 14px;
  color: #9b9b9b;
}

.no_of_travellers_checkbox_parent {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 8px;
}

.no_of_travellers_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.no_of_travellers_parent {
  /* padding: 0 20px; */
  /* margin-top: 14px; */
  /* border-radius: 10px; */
}

/* .no_of_travellers {
  margin: 15px 20px;
} */

.no_of_travellers_primary {
  margin-top: 20px;
}

.no_of_travellers span {
  font-size: 12px;
  color: #9b9b9b;
}

.no_of_travellers h5 {
  /* font-size: 12px; */
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
}

.traveller_adult_card {
  width: 82px;
  height: 80px;
  border-radius: 4px;
  position: relative;
  border: 1px dashed #008cff;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.traveller_adult_card.selected {
  border: 1px solid #008cff;
  background: #eaf5ff;
}

.traveller_adult_form_details {
  margin-top: 20px;
}

.traveller_adult_card .primary_checkbox {
  position: absolute;
  bottom: 3px;
  right: 3px;
}
.traveller_adult_card .traveller_adult_card_tick {
  position: absolute;
  right: 0;
  top: 0;
}
.traveller_adult_card svg {
  margin: 0;
}
@media (width<=768px) {
  .traveller_details_popup {
    width: 90%;
  }
}

@media (width<=640px) {
  .traveller_details_popup {
    width: 95%;
  }
  .traveller_details_popup input {
    width: 100%;
  }
  .no_of_travellers_parent {
    padding: 0 10px !important;
  }
}
