.propertyImagesContainer{
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 10px;
   flex-wrap: wrap;
}

.propertyImagesContainerDelete:hover {
    display: flex;
   
    
}
.propertyImagesContainerDelete {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    background-color: aliceblue;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 10px;
    display: none;
}
.propertyImagesContainerDelete svg {
    margin: 0; 
}
.propertyImagesContainerDiv{
    position: relative;
    cursor: pointer;
}
.propertyImagesContainerDiv:hover{
    .propertyImagesContainerDelete{
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .propertyImagesContainer{
        grid-template-columns: repeat(3, 1fr);
    }
    
}
@media screen and (max-width: 680px) {
    .propertyImagesContainer{
        grid-template-columns: repeat(2, 1fr);
    }
    
}
@media screen and (max-width: 480px) {
    .propertyImagesContainer{
        grid-template-columns: repeat(1, 1fr);
    }
    
}