@import '../setting/_settings.scss';


.custom_slider {
  position: relative;

  .slider_track {
    width: 100%;
    height: 4px;
    background-color: #d9d9d9;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    .slider_thumb {
      width: 13px;
      height: 13px;
      // background-color: transparent;
      border: 3px solid $primary-color;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      background-color: $color-white;
      transform: translate(-50%, -50%);
      cursor: grab;
      z-index: 2;

      &:nth-child(2) {
        background-color: $color-white;
      }
    }

    .slider_thumb:active {
      cursor: grabbing;
    }

    .slider_fill {
      height: 100%;
      background-color: $primary-color;
      border-radius: 5px 0 0 5px;
      position: absolute;
      top: 0;
    }
  }

  .slider_values {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 15px;
    width: 100%;

    span {
      position: absolute;
      transform: translateX(-50%);
      font-family: $Inter;
      font-size: 10px;
      font-weight: 500;
      line-height: 17px;
      color: $ghost-btn-active-color;
    }
  }
}
