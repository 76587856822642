.LoginVender_topHeader {
}

.LoginVendor_padding {
  padding: 40px 0;
}

.LoginVendor_heading {
  text-align: center;
  /* font-size: 30px; */
  font-weight: 600;
  margin-bottom: 28px;
}

.LoginVendor_shade {
  background-color: #ffffff;
}

.LoginVendor_banner {
  background-image: url(../../assets/images/vendorBackground.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.LoginVendor_banner::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #daf5e8d9;
}

.LoginVendor_head {
  padding: 5px 0 5px;
  position: relative;
}

.LoginVendor_form_cont input {
  height: 35px;
  width: 100%;
  color: #333333;
  padding-left: 12px;
  padding-right: 12px;
}

.LoginVendor_form_cont input::placeholder {
  color: #666666;
  font-size: 14px;
}

.LoginVendor_form_cont button {
  padding: 7px 16px;
  background-color: #eb442d;
}

.LoginVendor_form_cont a {
  text-decoration: underline;
  color: #888888;
  text-underline-offset: 2px;
}

.LoginVendor_form_submit {
  margin: 15px 0;
}

.LoginVendor_banner_content {
  text-align: left;
  width: 50%;
}

.LoginVendor_banner_content h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: left;
}

.LoginVendor_banner_content p {
  font-size: 14px;
  width: 90%;
  margin: 15px 0 20px;
}

.LoginVendor_banner_getStarted small {
  color: #ffffff;
  font-size: 12px;
}
.LoginVendor_banner_getStarted:hover small {
  color: #eb442d !important;
  font-size: 12px;
}
.LoginVendor_banner_form {
  background-color: #ffffff;
  padding: 20px;
  width: 28%;
  border-radius: 12px;
}

.LoginVendor_form {
  gap: 15px;
}

.LoginVendor_form_btn {
  text-align: center;
}

.LoginVendor_form_btn button {
  width: 100%;
}

.LoginVendor_form h2 {
  font-size: 30px;
  font-weight: 600;
}

.LoginVendor_banner_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

/* How It Works */
.LoginVendor_wokingMain {
  gap: 15px;
}

.LoginVendor_wokingCard_number span {
  color: #ffffff;
}

.LoginVendor_wokingCard {
  /* height: 150px; */
  width: 31%;
  padding: 10px;
  border: 1px solid #cccccc7e;
  border-radius: 6px;
}
.LoginVendor_wokingCard_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginVendor_wokingCard_content h5 {
  font-weight: 800;
  color: #009a50;
}

/* Features */
.LoginVendor_featuresMain {
  flex-wrap: wrap;
}
.LoginVendor_featuresCard_content small {
  line-height: 16px;
}
.LoginVendor_featuresCard {
  width: 25%;
  text-align: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #cccccc7e;
  border-bottom: 1px solid #cccccc7e;
}
.LoginVendor_featuresCard:nth-child(4) {
  border-right: 0;
}
.LoginVendor_featuresCard:nth-child(8) {
  border-right: 0;
}
.LoginVendor_featuresCard:nth-child(5) {
  border-bottom: 0;
}
.LoginVendor_featuresCard:nth-child(6) {
  border-bottom: 0;
}
.LoginVendor_featuresCard:nth-child(7) {
  border-bottom: 0;
}
.LoginVendor_featuresCard:nth-child(8) {
  border-bottom: 0;
}

.LoginVendor_featuresCard_icon {
  justify-content: center;
}

.LoginVendor_featuresCard_icon img {
  width: 45px;
  height: 45px;
}

.LoginVendor_featuresCard_content h4 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}

.LoginVendor_featuresCard_content p {
  color: #666666;
  font-size: 12px;
}

/* Register Form */
.LoginVendor_registerMain {
  justify-content: center;
  height: 100%;
  margin-left: 140px;
}

.LoginVendor_registrationRight {
  padding: 30px;
  background-color: #cff2e1;
}

.LoginVendor_registerForm {
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  height: 100%;
  /* background-color: #FFFFFF; */
  /* border: 1px solid #15AA12; */
  /* border-radius: 12px; */
  /* padding: 16px 20px; */
}

.LoginVendor_registerForm_btn button {
  width: 100%;
}

.LoginVendor_registrationRight {
  width: 55%;
}

.LoginVendor_regMain {
  background-color: #ffffff;
  /* border-radius: 12px; */
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}
.Register_heading {
  text-align: left !important ;
  margin-bottom: 10px !important;
}
.circle {
  border-radius: 50%;
  background-color: #fff;
  height: 150px;
  width: 150px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  position: relative;
}
.fixed-line {
  border: 3px solid #009a50;
  width: 50%;
  position: absolute;
  z-index: 1;
}
.main-circle{
  z-index: 2;
}
.flex-packages {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 45%;
  margin-right: 150px;
  padding: 10px 0;
}
.heading-6 {
  color: #eb442d;
  text-align: center;
  margin-top: 10px;
}
@media screen and (max-width: 1250px) {
  .LoginVendor_registerMain{
    margin-left: 0;
  }
  .flex-packages {
    margin-right: 0;
  }
}
@media screen and (max-width: 992px) {
  .fixed-line {
    width: 80%;
  }
  .LoginVendor_banner_form {
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  .LoginVendor_registrationRight {
    padding: 20px;
    width: 100%;
  }
  .LoginVendor_regMain {
    flex-direction: column;
    gap: 10px;
  }
  .work-flow {
    flex-direction: column;
    gap: 20px;
  }
  .fixed-line {
    height: 92%;
    width: 0;
  }
  .LoginVendor_wokingCard {
    width: 48%;
  }
.flex-packages{
  padding: 10px 0;
}
  /* .heading-6:nth-child(1) {
    text-align: left !important;
    display: none;
  } */
  /* .heading-6:nth-child(even){
    color: #009a50;
  } */
  /* .heading-6{
    color: transparent
   } */
  .heading-6-even {
    padding-left: 100px;
  }
  .heading-6-odd {
    padding-right: 100px;
  }
  .flex-packages {
    width: 100%;
    /* justify-content: start; */
    padding: 10px;
  }
}

@media (width<=640px) {
  .LoginVendor_wokingCard_content h2 {
    font-size: 12px;
  }

  .LoginVendor_wokingCard {
    width: 48%;
    /* height: 150px; */
  }
  .LoginVendor_banner_form {
    width: 100%;
  }
  .LoginVendor_featuresCard {
    width: 50% !important;
    padding: 10px !important;
  }

  .LoginVendor_featuresCard:nth-child(5) {
    border-bottom: 1px solid #cccccc7e !important;
  }
  .LoginVendor_featuresCard:nth-child(2) {
    border-right: 0;
  }
  .LoginVendor_featuresCard:nth-child(6) {
    border-bottom: 1px solid #cccccc7e !important;
  }
  .LoginVendor_banner_content h3 {
    font-size: 25px;
    line-height: 34px;
  }
  .LoginVendor_heading {
    margin-bottom: 20px;
  }

  .Register_heading {
    font-size: 24px;
  }
  .LoginVendor_img {
    display: none;
  }
  .LoginVendor_banner_main,
  .LoginVendor_wokingMain,
  .LoginVendor_featuresMain {
    flex-wrap: wrap;
  }
  .LoginVendor_banner_content,
  .LoginVendor_banner_form {
    width: 100%;
  }
  .LoginVendor_banner_main {
    gap: 30px;
  }
}

@media (width<=500px) {



  .LoginVendor_wokingMain {
    justify-content: center;
  }

  .LoginVendor_wokingMain {
    justify-content: center;
  }

  .Register_heading {
    font-size: 18px;
  }
}

@media (width<=375px) {
  .LoginVendor_registrationRight {
    padding: 10px;
  }

  .LoginVendor_banner_main {
    gap: 30px;
  }
  .Register_heading {
    font-size: 18px;
  }
}


.Register_Agent_Vendor_Form {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .Register_Agent_Vendor_Form {
    width: 100%;
  }
}