.SingleRoomCard-Main{
    margin-top: 20px;
}
.room_plan {
    display: flex;
    align-items: center;
    /* gap: 5px; */
}
.SingleHotelCard-roomcard{
    gap: 10px;
}
.single-room-card{
    height: auto;
}
.SingleHotel-Details{
    height: auto;
}
.Singlehotel_price{
    font-size: 16px !important;
}