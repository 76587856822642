.LoginAgent_banner {
  background-image: url(../../../assets/images/agentBanner.png);
  background-position: 0px -70px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.LoginAgent_banner::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #DAF5E8D9
}

.LoginVendor_featuresCard {
  width: 25%;
  text-align: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #cccccc7e;
  border-bottom: 1px solid #cccccc7e;
}

.LoginVendor_featuresCard:nth-child(4) {
  border-right: 0;
}

.LoginVendor_featuresCard:nth-child(8) {
  border-right: 0;
}

.LoginVendor_featuresCard:nth-child(5) {
  border-bottom: 0;
}

.LoginVendor_featuresCard:nth-child(6) {
  border-bottom: 0;
}

.LoginVendor_featuresCard:nth-child(7) {
  border-bottom: 0;
}

.LoginVendor_featuresCard:nth-child(8) {
  border-bottom: 0;
}

.Register_Agent_Vendor_Form {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .Register_Agent_Vendor_Form {
    width: 100%;
  }
}