.HotelCheckout-form {
  width: 30%;
}
.HotelCheckout-Input {
  /* height: 40px; */
  width: 100%;
}
@media (max-width: 640px) {
  .CheckoutHotel-Fields {
    width: 100% !important;
  }
  .input-flex {
    flex-wrap: wrap;
  }
  .Checkout-time {
    font-size: 12px;
  }
}
.hotel-description {
  --tw-text-opacity: 1 !important;
  color: #333;
}
.Checkouthotel-checkout {
  padding: 0 50px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.Checkouthotel-dates {
  width: 50%;
}
@media (width<=640px) {
  .Checkouthotel-dates {
    width: 100%;
  }
  .Checkouthotel-checkout {
    padding: 0 30px;
    border-right: none;
  }
  .Checkouthotel-Guests h6 {
    text-align: left !important;
  }
  .Checkouthotel-main {
    flex-wrap: wrap;
  }
}
.Checkouthotel-Guests h6 {
  text-align: center;
}

.discountSingleDiv {
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
}
