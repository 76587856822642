
/* @media (width<=370px) {
    .LoginBtn-main{
width: 30px;
    }
} */

.LoginBtn-main{
 background: linear-gradient(90deg, #00C767 0%, #016937 100%);
 color: #FFFFFF;

}