.tabBar {
  width: 85%;
}

.top-tab-section {
  border: 0;
  background: #fff;
}

.tab-bar {
  border: 1px solid #cccccc4d;
  /* padding-top: 20px; */
}

.tab-btn-div {
  margin: 0 auto;
  width: 65%;
  border-radius: 4px;
  border-left: 0;
  display: flex;
  /* justify-content: space-around; */
}

.tab-btn {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-right: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 0;
  /* transition: all 2s; */
}

.tab-btn span {
  background-color: #bebebe;
  font-size: 10px;
  border-radius: 20px;
  padding: 1px 5px;
  border: 1px solid #ccc;
  transition: all 0.3s;
  /* border-top: 1px solid #ccc; */
}

.tab-btn.active span {
  background: #fff;
  border: 1px solid #ccc;
}

.tab-bar .active {
  border-bottom: 2px solid #016937;
  background-color: #cccccc4d;
}
@media (max-width: 768px) {
  .tabBar {
    width: 100%;
  }
}
