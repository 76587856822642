.childAge_input {
  text-align: center;
}

.NewSearch-BG {
  background-image: url(../../../assets/images/top-banner.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewSearch-Nav {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
}

.NewSearch-Logo {
  width: 55px !important;
  height: 45px !important;
}

.NewSearch-Main {
  width: 100%;
}

.NewSearchActivities-header {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.NewSearch-Heading {
  text-align: center;
  font-size: 20px;
  margin-bottom: 4px;
  margin-top: 40px;
}

.flex_center {
  display: flex;
  align-items: center;
}

.NewSearch-links {
  color: #333333;
  background-color: white;
  padding: 9px 20px;
  margin: 2px 4px;
  border-radius: 6px;
  box-shadow: 0px 4px 5px 0px #00000026;
  transition: ease-in-out 0.3s;
}

.NewSearch-links:hover,
.active-link {
  color: #ffffff;
  fill: #ffffff;
  background-color: #bb735d;
  box-shadow: 0px 4px 5px 0px #00000026;
}

.NewSearch-tags label {
  color: #888888 !important;
}

.NewSearch-tags {
  width: 100% !important;
}

/* .NewSearch-tags input,
.NewSearch-guestcount {
    font-size: 16px !important;
    font-weight: 700 !important;
} */

.NewSearch-Login {
  background-color: #ffffff;
  right: 0px;
}

.NewSearch-support {
  color: #ffffff;
}

.Radio_btn {
  padding-bottom: 5px;
}

.CustomSearch-Holiday-Radiobtn:hover .Radio_btn {
  border-bottom: 1px solid;
}

.new_date_component {
  background-color: #f8f7f7;
  border-radius: 5px;
}

.NewCustom_banner {
  align-items: flex-end;
  justify-content: center;
}

.new_form {
  padding: 20px 25px;
  box-shadow: 10px 13px 21px -1px rgba(0, 0, 0, 0.3);
}

.newinput {
  background-color: #f8f7f7;
  color: #333333;
  border-radius: 5px;
}

.new_inputDiv {
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
}

.new_btn {
  border-radius: 5px;
  margin: 0;
}

.newDates {
  padding: 7px 10px;
  text-align: center;
  justify-content: space-around;
  display: flex;
  align-content: center;
}

.CustomSearch-Holiday-Radiobtn span,
.newDates {
  color: #333333;
  font-size: 12px;
}

.childAge_input {
  text-align: center;
}

.search_form_input:focus-visible {
  outline: none;
}

.form_filters {
  gap: 5px;
}
@media (max-width: 768px) {
  .NewSearch-Login {
    right: 40px;
  }
}

@media (max-width: 640px) {
  .NewSearch-Login {
    right: 0px;
  }
}

@media (max-width: 1024px) {
  .NewSearch-Main {
    width: 95%;
  }

  #activityBanner {
    height: 280px;
  }

  .NewSearchActivities-header {
    top: 0;
  }

  #activitySearchForm {
    top: 15%;
  }

  .NewSearchActivities-header {
    top: 7%;
    width: 100%;
  }
}
