
.Header_links {
  padding: 6.5px 10px;
  background-color: #f3f3f3;
  border-radius: 5px;
  gap: 6px;
}

.Header_links_wraper {
  gap: 25px;
}

.Header_links.active {
  background-color: #fff0eb;
  color: #eb442d;
}

@media (width<=640px) {
  /* nav-link{
       display: block;
    } */
  .HeaderMain-content {
    width: 90% !important;
  }
}

@media (width>=637px) and (width<=1110px) {
  /* .Main-header-btn {
    width: 18% !important;
  } */
}

.Main-header-btn {
  /* width: 35%; */
  text-align: end;
}

@media (width<=375px) {
  .Main-header-btn {
    width: 50% !important;
    padding-right: 5px;
  }
}

.HeaderMain-content {
  width: 100%;
}

.Mainregister-btn {
  width: auto;
}

.Header-main {
  /* width: 34%; */
}

@media (width<=370px) {
  .Mainregister-btn {
    width: 60%;
    margin-right: 10px;
  }
}

@media (width<=768px) {
  .Header select {
    display: none;
  }
}

@media (width<=500px) {
  .Mainregister-btn {
    width: 37%;
  }
}

