.Single_pkg_header-main {
  justify-content: space-between;
  background-color: #ffffff;
  margin: 20px 0 20px 0;
  border: 1px solid rgba(156, 170, 179, 0.28);
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.Single_pkg_header-head {
  /* width: 100%; */
  flex-wrap: wrap;
  gap: 10px;
}

.Single_pkg_header-cards {
  padding: 0 40px 0 15px;
  margin: 15px 0 15px 0;
}

@media (max-width: 640px) {
  .SinglePkgHeader_pkg-name {
    /* width: 35%; */
  }

  .Single_pkg_header-cards {
    padding: 0 10px 0 12px !important;
    margin-bottom: 0;
    border: none !important;
  }

  .Single_pkg_header-form_content h1 {
    font-size: 10px !important;
    /* text-wrap: wrap; */
    line-height: 15px;
  }

  .Single_pkg_header-Guests {
    font-size: 10px;
    line-height: 12px !important;
  }

  .Single_pkg_header-head {
    gap: 5px;
  }

  .Single_pkg_header-btn {
    font-size: 12px !important;
  }
}

.Single_pkg_header-input input {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.Single_pkg_header-location input {
  margin-top: 7px;
}

.Single_pkg_header-btn {
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #cccccc;
}

.Single_pkg_header-btn strong {
  font-weight: 500;
}

.Single_pkg_header-access {
  justify-content: end;
  padding-right: 15px;
}

.Single_pkg_header-modify {
  position: relative;
  top: 25%;
  background-color: #ffffff;
  border-radius: 5px;
  width: 50%;
}

.Single_pkg_header-modify_main {
  padding: 20px 0px;
}

.Single_pkg_header-modify_head {
  border-top: 1px solid #cccccc7c;
  border-bottom: 1px solid #cccccc7c;
  justify-content: space-between;
}

/* .Single_pkg_header-modify_cards {
    width: 20%;
} */

.Single_pkg_header-close {
  position: absolute;
  right: 10px;
  border: 1px solid #bb735d;
  color: #ffffff;
  background-color: #bb735d;
  padding: 7px 15px;
  margin-top: 10px;
  border-radius: 7px;
}

.Single_pkg_header_apply {
  padding: 10px;
  border: 1px solid #cccccc7c;
  color: #ffffff;
  background-color: #bb735d;
  border-radius: 7px;
  width: 120px;
}

.Single_pkg_header_modify_icon {
  display: none;
}

.Single_pkg_header-guest_details {
  font-size: 15px;
}

.package_flex_gap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.show_Half_package_title {
  color: black;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  /* Adjust width as needed */
  display: block;
}

@media (width<=768px) {
  .Single_pkg_header-modify {
    width: 95%;
  }

  .SinglePkgHeader_pkg_text {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Single_pkg_header_apply {
    width: 70px;
  }

  .Single_pkg_header-input input,
  .SingleSearch-Guests {
    font-size: 10px !important;
  }

  .SinglePkgHeader_pkg_text {
    font-size: 15px !important;
  }

  .Single_pkg_header-Guests h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Single_pkg_header_modify_icon {
    display: block;
  }

  .Single_pkg_header_modify {
    display: none;
  }

  .package_flex_gap {
    align-items: end;
  }
}

@media (width<=500px) {
  .Single_pkg_header-access {
    width: 20%;
    justify-content: center;
  }

  .Single_pkg_header-main {
    flex-wrap: wrap !important;
    /* justify-content: left; */
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: 15px;
  }

  .Single_pkg_header-resp {
    flex-wrap: wrap;
  }

  .Single_pkg_header-guest_details {
    font-size: 10px;
  }

  .Single_pkg_header-resp-cards {
    width: 100%;
  }

  .Single_pkg_header-modify_cards {
    width: 33.33%;
  }

  .Single_pkg_header-modify_main {
    padding: 10px 0px 5px;
  }

  .Single_pkg_header-resp-cards_content {
    border-bottom: 1px solid #cccccc7c;
  }

  .Single_pkg_header-modify_head {
    border-bottom: none;
    padding-bottom: 10px;
  }

  .Single_pkg_header_apply {
    width: 100px;
  }

  .Single_pkg_header-head {
    width: 80%;
  }

  /* .Single_pkg_header-cards {
    overflow: hidden;
  } */
  .guest_details_dropdown {
    top: 25px;
  }

  /* .Single_pkg_header-access {
    padding: 15px;
  } */

  .Single_pkg_header-btn {
    padding: 2px 4px !important;
  }
}

@media (width<=375px) {
  /* .Single_pkg_header-head {
    width: 100%;
  } */

  .Single_pkg_header-cards {
    padding: 0 5px 0 5px !important;
  }

  .Single_pkg_header-access {
    /* flex-direction: column; */
    align-items: center;
  }

  /* .Single_pkg_header-head {
        width: 100%;
    }

    .Single_pkg_header-access {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border-top: 1px solid #cccccc7c;
        justify-content: center;
    }
    .Single_pkg_header-cards{
        padding: 0 10px 0 10px !important;
    } */
  .Single_pkg_header-cards small {
    font-size: 8px;
  }

  .Single_pkg_header-cards label {
    font-size: 8px;
  }
}

@media (width<=640px) {
  .Single_pkg_header_modify {
    display: none;
  }

  .Single_pkg_header-cards {
    margin-top: 5px;
  }
}