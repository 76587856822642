.agentPromotionParent{
    background-color: rgb(223, 223, 223);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer
}

.agentPromotionParentDiv{
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    flex-direction: column;
}
.agentPromotionParentInnerDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    padding: 20px;
}

.agentPromotionParentInnerOneDiv{
    width: 10%;
}
.agentPromotionParentInnerTwoDiv{
    width: 45%;
}
.agentPromotionParentInnerThirdDiv{
    width: 45%;
}
.agentPromotionParentInnerFourthDiv{
    width: 5%;
}





/* next */
.promotionNextAddInnerParent{
    width: 100%;
    background-color: aliceblue;
    padding: 20px;
    border-radius: 10px;
}
.promotionNextAddInnerParent h4{
    margin-bottom: 15px;
}


.promotionNextAddInnerChildFlex{
    display: flex;
    gap: 50px;
}