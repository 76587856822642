.CustomSliderTaxi_Close {}

.CustomSliderTaxi_MainClose {
    /* margin-top: 5px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #cccccc7c;
    padding: 10px 12px;
}