.common_title h2 {
  color: #333;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.h_100 {
  height: 100%;
}

.h-20 {
  height: 20%;
}

.px_20 {
  padding: 0 20px;
}

.font-3 {
  font-weight: 300;
}

.p_0 {
  padding: 0 !important;
}

.py-0 {
  padding: 0 10px !important;
}

.py-15 {
  padding: 15px 0
}

.p_10 {
  padding: 10px !important;
}

.py-5 {
  padding: 5px 0 !important;
}

.p-5 {
  padding: 5px;
}

.px-10 {
  padding: 0 10px;
}

.px-7 {
  padding: 0 7px;
}

.p_20 {
  padding: 20px !important;
}

.py-10 {
  padding: 10px 0;
}

.pb_20 {
  padding-bottom: 20px !important;
}

.pb_30 {
  padding-bottom: 30px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pt_10 {
  padding-top: 10px !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.p_b_10 {
  padding-bottom: 10px !important;
}

.p_top_0 {
  padding-top: 0px !important;
}

.bg-white {
  background-color: #fff;
}

.bg {
  background-color: #016937;
}

.border-right-one {
  border-right: 1px solid #cccccc7c !important;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-top {
  border-top: 1px solid #ccc;
}

.border-radius {
  border-radius: 30px !important;
}

.rounded {
  border-radius: 6px;
}

.theme_color {
  color: #3968ed;
}

.text-white {
  color: white !important;
}

.text-red {
  color: red;
}

.block {
  display: block;
}

.p_top_bottom_20 {
  padding: 20px 0;
}

.m_0_auto {
  margin: 0 auto !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.m_0 {
  margin: 0 !important;
}

.mb_5 {
  margin-bottom: 5px;
}

.ml_15 {
  margin-left: 15px;
}

.flex {
  display: flex !important;
}

.flex_direction_col {
  flex-direction: column;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.align_items_start {
  align-items: flex-start !important;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 5px
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-20 {
  padding-right: 20px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.w-full {
  width: 100% !important;
}
.w-auto {
  width: auto;
}

.max-width {
  width: max-content;
}

.fit-width {
  width: fit-content !important;
}

.w-half {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-70 {
  width: 70%;
}

.text-black {
  color: #000;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-100 {
  width: 100% !important;
}

.w-33 {
  width: 33%;
}

.sticky {
  position: sticky;
  top: 10px;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.justify_content_center {
  justify-content: center !important;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_evenly {
  justify-content: space-evenly
}

.no-scroll {
  overflow: hidden !important;
}

.justify_content_between {
  justify-content: space-between !important;
}

.flex_gap_10 {
  gap: 10px;
}

.flex_gap_5 {
  gap: 5px;
}

.flex_gap_3 {
  gap: 3px;
}

.flex_gap_15 {
  gap: 15px;
}

.flex_gap_20 {
  gap: 20px !important;
}

.flex_gap_50 {
  gap: 50px !important;
}

.flex_wrap {
  flex-wrap: wrap !important;
}

.flex_nowrap {
  flex-wrap: nowrap !important;
}

.font_12 {
  font-size: 12px;
}

.font_11 {
  font-size: 11px;
}

.font_16 {
  font-size: 16px;
}

.rounded_0 {
  border-radius: 0px !important;
}

.border_0 {
  border: none !important;
}

.text-white {
  color: white !important;
}

.icon_button_only {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.overflow_x_scroll_y_hidden {
  overflow-x: auto;
  overflow-y: hidden;
  cursor: pointer;
}

.overflow_y_scroll_x_hidden {
  overflow-x: hidden;
  overflow-y: auto;
  /* cursor: pointer; */
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar {
  display: block;
  height: 5px;
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-thumb {
  background-color: gray;
  border-right: none;
  border-left: none;
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-thumb {
  background-color: gray;
  border-right: none;
  border-left: none;
}

.pointer {
  cursor: pointer;
}

.flex-basis-10 {
  flex-basis: 10%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-30 {
  flex-basis: 30%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.hoverCursor {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.flex_align_center {
  display: flex !important;
  align-items: center;
}

.border {
  border: 1px solid #ccc !important;
}

.icon_btn {
  background-color: #016737;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.icon_btn svg {
  color: #fff;
  margin: 0;
  /* width: 12px;
  height: 12px; */
}

.contactUsBg {
  background-color: #cccccc42;
  padding: 20px;
}

.filterBlur3 {
  filter: blur(3px);
}

@media (width<=768px) {
  .contactUsBg {
    padding: 10px;
  }
}