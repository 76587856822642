.SliderTaxiCard_MainHeading {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
}

.SliderTaxiCard_Main {

  border: 1px solid #cccccc7c;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;

}
.Taxi_img{
  display: flex;
  align-items: end;
  justify-content: center;
}

@media (width <=640px) {
  .SliderTaxiCard_Main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Taxi_img {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-right: 0.5px solid #cccccc7c; */
  }

  .SliderTaxiCard_content {
    width: 75%;
  }
}

.SliderTaxiCard_content {
  width: 100%;
}

.SliderTaxiCard_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.SliderTaxiCard_content {
  padding: 10px 12px;
}

.SliderTaxiCard_Provides {
  display: flex;
  gap: 5px;
  font-size: 12px;
  padding: 9px 0;
  border-top: 1px solid #cccccc7c;
  border-bottom: 1px solid #cccccc7c;
}

.SliderTaxiCard_Provides h4 {
  color: #4a4a4a;
  font-size: 12px;
}

.SliderTaxiCard_rates {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}