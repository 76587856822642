.multipleImageUpload {
    .fileInputMedia {
        align-items: center;
        border: .5px dashed #888;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        width: 100%;
        position: relative;

        .selectFileType {
            z-index: 2;
            position: absolute;
            top: 0;
            right: 0;
            // width: 100%;
            // height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            label {
                color: #000;
                cursor: pointer;
                background-color: transparent;

            }
        }

        p {
            color: #888;
            font-size: 10px;
            font-weight: 400;
            margin: 5px 0 0;
        }

        label {
            background: #ecf1fd;
            border-radius: 4px;
            color: #3968ed;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            padding: 5px;
        }

        input {
            cursor: pointer;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;

        }

    }

    .previewContainer {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;


        .previewImage {
            width: 100px;
            height: 100px;
        }

        div {
            position: relative;

            button {
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgb(0, 0, 0);
                border-radius: 50%;
                border: none;
                cursor: pointer;
                font-size: 15px;
                color: #fff;
            }
        }
    }
}