.activity-slider {
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
}

.singleActivity .arrowLeft {
  background-image: url("../../assets/images/backActivity.png") !important;
  left: 10px !important;
}

.singleActivity .arrowRight {
  background-image: url("../../assets/images/nextActivity.png") !important;
  right: 10px !important;
}

.slideArrows {
  background-color: #FFFFFF;
  border-radius: 20px;
  z-index: 99;
  height: 30px;
  width: 30px;
}
.slideArrows:hover{
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  .SingleActivities-overview {
    width: 70% !important;
  }
}