.transport_card_listing_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border: 0.5px solid #CCCCCC;
    background-color: #ffffff;
    border-radius: 10px;
}

.transport_card_listing_page_image {
    width: 30%;
    height: 210px;
}

.transport_card_listing_page_content {
    width: 45%;
    font-family: "Inter", sans-serif;
    padding: 10px;
}

.transport_card_listing_page_content h2 {
    font-size: 17px;
    font-weight: 600;
    line-height: 21.78px;
    color: #333333;
}
.transport_card_listing_page_content p {
    font-size: 13px;
}


.transport_card_destinations .transport_card_destinations_child p {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #EB442D;
}

.transport_card_destinations .transport_card_destinations_child h6 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14.52px;
    color: #333333;
}

.transport_card_listing_page_price {
    width: 25%;
    text-align: end;
    padding: 10px;
    border-left: 0.5px solid #DDDDDD;
}

.transport_card_dashed_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
}

.transport_circle {
    width: 10px;
    height: 10px;
    background-color: #AAAAAA;
    border-radius: 50%;
}

.dashed-line {
    flex-grow: 1;
    height: 2px;
    border-top: 2px dashed #AAAAAA;
}

.transport_options h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 10px;
}

.transport_options .transport_options_cabs {
    padding: 4px 8px;
    gap: 10px;
    border-radius: 4px;
    border: 0.5px;
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    border: 0.5px solid #CCCCCC;
    display: inline;
}

.transport_options_cabs_ac {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #009A50;
}

.itenarary_page {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.itenarary_page_left {
    width: 30%;
}

.itenarary_page_right {
    width: 70%;
}

.itenarary_detail {
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;
    font-family: "Inter", sans-serif;
    position: sticky;
    top: 0;
}

.itenarary_detail_left p {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
    color: #666666;
}

.itenarary_detail_left h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 27.84px;
    color: #333333;
}

.transport_cabs_card {
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #CCCCCC;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
}

.transport_cabs_card_top {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.transport_cabs_card_bottom {
    display: flex;
    justify-content: space-between;
}

.transport_cabs_card_bottom_image {
    width: 30%;
}

.transport_cabs_card_bottom_content {
    width: 70%;
}

.transport_cabs_card_bottom_content_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.itenarary_destinations_item {
    border: 0.5px solid #CCCCCC;
    padding: 10px;
    border-radius: 5px;
}

.tranport_checkout_details_right {
    text-align: right;
}

@media (max-width: 768px) {
    .transport_cabs_card {
        flex-wrap: wrap;
    }

    .transport_card_listing_page {
        flex-direction: column;
    }

    .transport_card_listing_page_image {
        width: 100%;
    }

    .transport_card_listing_page_content {
        width: 100%;
        padding: 10px;
    }

    .transport_card_listing_page_price {
        width: 100%;
        text-align: start;
        /* text-align: start;
        display: flex;
        justify-content: space-between; */
    }

    .transport_card_listing_page_price div {
        justify-content: start;
    }

    .itenarary_page {
        flex-direction: column;
    }

    .itenarary_page_left {
        width: 100%;
    }

    .itenarary_page_right {
        width: 100%;
    }

    .itenarary_detail {
        padding: 10px;
    }

    .transport_cabs_card_bottom {
        flex-direction: column;
    }

    .transport_cabs_card_bottom_image {
        width: 100%;
    }

    .transport_cabs_card_bottom_content {
        width: 100%;
    }

    .transport_cabs_card_bottom_content_price {
        flex-direction: column;
        align-items: end;
    }

    .flex_col_mobile {
        flex-direction: column;
    }

    .tranport_checkout_details h3 {
        font-size: 16px;
    }

    .tranport_checkout_details_right {
        text-align: left;
        margin-top: 10px;
    }
}