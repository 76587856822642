/* Bedding */
.bedding-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}
 
.bedding_text {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
 
.toggle_text {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 5px;
}
 
.toggle-container {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}
 
.toggle-checkbox-custom {
  display: none;
}
 
.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.4s;
}
 
.toggle-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}
 
.toggle-checkbox-custom:checked+.toggle-slider:before {
  transform: translateX(15px);
  background-color: #15aa12;
  left: 14px;
}