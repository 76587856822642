.CustomCard {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    width: 100%;
  }
  
  .CustomCard img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  /* .card_content {
    margin-top: 10px;
  }
   */
  .agent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .buttons {
    margin-top: 10px;
  }
 
  