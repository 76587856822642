.form_container {
  max-width: 600px;
  margin: 0 auto;
}
.error {
  font-size: 10px;
  color: red;
}
.hideCheck {
  display: none;
}
.multistep_form_para {
  font-size: 12px;
  padding: 10px;
}
.prev_btn {
  background-color: black;
  width: 50px;
  border-radius: 4px;
}
