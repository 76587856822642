.highlight img {
    width: 50px;
    height: 40px;
    object-fit: contain;
}
.highlight{
    padding: 10px 20px;
}


@media (max-width: 640px) {

    .highlightSection {
        display: flex;
        flex-wrap: wrap;
    }
    .highlight{
        padding: 10px;
    }
}