@media (width<=640px) {
  .NewSearch-Nav {
    justify-content: space-between;
    /* width: 90%; */
  }
  .NewSearch_mmt {
    width: 55px;
    height: 45px;
  }
  .Top-header_links {
    color: #333 !important;
    display: block !important;
  }
  .Top-header_links svg {
    fill: #333 !important;
  }
  .NewSearch-Nav {
    right: 10px;
  }
}
.NewSearch_mmt {
  width: 55px;
  height: 45px;
}
.NewSearch-support{
  color: #333;
}