.selected-destinations {
    max-width: 180px;
    position: relative;
}

.selected-destinations span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #555;
}

.selected-destinations .clear-btn {
    cursor: pointer;
    border: none;
    color: "#555";
    position: absolute;
    right: 0;
    top: 0;
}