.BannerMain-Img {
    background-image: url("../../assets/images/mainBanner.png");
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: left;
    align-items: center;
}

/* @media (width <= 490px) {
    .BannerMain-Content{

    }
} */

@media (max-width:640px) {
    .BannerMain-Img {
        height: fit-content !important;
    }
    .BannerMain-Content{
        padding-left: 10px !important;
        padding-top: 10px;
        padding-bottom: 10px;
       
        /* height: 100px; */
    }

    .BannerMain-Content h3 {
        font-size: 12px !important;
    }

    .BannerMain-Content p {
        font-size: 8px;
        line-height: 12px;
    }
    .BannerMain-Content button{
        margin-top: 3px !important;
        padding: 0px 8px !important;
        font-size: 8px;
        border-radius: 3px !important;
    }
}
@media (min-width:768px) {
    .BannerMain-Content h3 {
        font-size: 33px !important;
    }
}

.BannerMain-Content {
    padding-left: 40px;
    width: 50%;
}

.BannerMain-Content h3,
.BannerMain-Content h4 {
    text-align: left;

}

.BannerMain-Content h3 {
    font-size: 36px;
    color: #684742;
}

.BannerMain-Content button {
    margin-top: 15px;
    padding: 5px 15px;
    border: 1px solid #684742;
    color: #684742;
    border-radius: 5px;
}