.AccountDetailContent{
    
}

.AccountDetails_dropdown{
    width: 30px;
    height: 30px;
    background-color: #cccccc7c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
}
.accountDropdown_main{
    width: 100%;
    transition: all;
  transition-duration: 0.7s;
}
.accountDropdown_main:hover {
    background-color: #cfcfcf41;
  }