#singleSearch input::placeholder,
#singleSearch label {
    color: #333 !important;
}

.SearchForm-guests span,
.flatpickr-input {
    color: #333;
    font-weight: 500;
    font-size: 12px;
}

.SingleSearch-Guests {
    font-size: 12px !important;
    font-weight: 400;
}

.SingleSearch-Resp {
    padding: 15px;
    border: 0.5px solid #333;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;

}

.SingleSearch-resp_content {
    margin-left: 12px;
}

.SingleSearch-resp_content h4 {
    font-size: 18px;
    font-weight: 600;
}



@media (width >=640px) {
    .SingleSearch-Resp {
        display: none;
    }
}