.sidebar {
  width: 17%;
  background-color: #fff;
  height: 100vh;
  position: sticky;
  top: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.sidebar_btn {
  display: block;
  padding: 10px 0;
  font-size: 14px;
  width: 100%;
  padding: 10px 20px;
  transition: all 0.3s;
  text-align: left;
}
.sidebar_btn.active {
  background-color: #daf5e8;
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: inherit;
  }
  
  .sidebar {
    display: none;
  }
  #sidebar {
    display: block;
    margin-top: 10px;
    box-shadow: none;
  }
  .nav .sidebar_btn {
    padding: 10px 0;
  }
}
