.packageAddActivityParent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:  30px 0px;
}
.packageAddActivityParent button {
  position: absolute;
  padding: 6px 8px;
  background-color: #daf5e8;
  color: #016937;

  border-radius: 10px;
}

.packageAddActivityLine{
  position: relative;
}