.amenities-btn {
    display: block;
    padding: 15px 10px;
    font-size: 12px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    width: 100%;
    transition: all 0.3s;

}

.amenities-sidebar {
    width: 25%;
    border: 1px solid #ccc;
    border-bottom: 0;
    height: auto;
}

.amenities-sidebar .active {
    background: #016937;
    color: white;
}

.amenities {
    display: flex;
}

.amenities-data {
    padding: 10px;
    width: 85%;
    font-size: 12px;
}

.amenity-btn {

    font-weight: bold;
}

.amenity-btn.active {
    border-bottom: 2px solid #048c4a;
    color: #048c4a;
}
.required{
    color: red;
}