.NewFooter_background {
  position: relative;
  z-index: 3;
}

.NewFooter_background a {
  transition: ease-in-out 0.3s;
}

.NewFooter_background a:hover {
  color: red;
}

.NewFooter_background::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, #daf5e8 75%, transparent 30%);

  width: 100%;
  height: 100%;
}

.NewFooter_Links {
  position: relative;
  border-radius: 12px;
  background-color: #ffffff;
}

.NewFooter_linksMain {
  margin-top: 30px;
  padding: 20px 30px;
}

.NewFooter_Border {
  border-right: 0.5px solid #cccccc;
}

.NewFooter_MainCards {
  width: 25%;
}

.NewFooter_linksCards {
  width: 80%;
}

.NewFooter_linksCards h4 {
  margin-bottom: 5px;
}

.NewFooter_linksCards ul li {
  margin-top: 3px;
  list-style: none;
  color: #666666;
}

/* Logo */

.NewFooter_Logo {
  position: relative;
  margin: 20px 0;
}

.NewFooter_logoImg {
  background: rgb(219, 246, 232);
  width: 80px;
  height: 80px;
  z-index: 1;
}

.NewFooter_logoImg img {
  object-fit: contain;
}

.NewFooter_Logo_line {
  border: 0.1px solid #cccccc;
  position: absolute;
  width: 100%;
  top: 50%;
}

.NewFooter_Logo_line::before {
  content: "";
  position: absolute;
  width: 100%;
}

/* Info */
.NewFooter_info {
  padding-bottom: 15px;
  position: relative;
  justify-content: space-between;
}

.NewFooter_copyRight {
  width: 40%;
}

.NewFooter_copyRight span {
  color: #666666;
  font-size: 11px;
  font-weight: 600;
}

.NewFooter_copyRight p {
  color: #888888;
  font-size: 10px;
  margin-top: 10px;
}

.NewFooter_social {
  text-align: right;
  width: 50%;
  justify-content: right;
}

.NewFooter_socialLinks {
  gap: 10px;
  margin-bottom: 15px;
}
.NewFooter_socialLinks_cards {
  transition: all ease-in-out 0.3s;
}
.NewFooter_socialLinks_cards:hover.NewFooter_socialLinks_cards div {
  background-color: black;
}
.NewFooter_socialLinks_cards:hover.NewFooter_socialLinks_cards div svg path {
  fill: #ffffff;
  /* color: #ffffff; */
}
.NewFooter_socialLinks_cards div svg path {
  transition: all ease-in-out 0.3s;
}

.NewFooter_socialLinks_cards div {
  transition: all ease-in-out 0.3s;
  background-color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  justify-content: center;
}

.NewFooter_socialLinks_cards div svg {
  margin: 0;
}

.NewFooter_detailsLinks {
  justify-content: end;
  margin-bottom: 15px;
}

.NewFooter_detailsLinks a {
  padding: 0 10px;

  border-left: 1px solid #cccccc;
  /* width: 20%; */
  font-size: 12px;
  color: #666666;
}

@media (width<=640px) {
  .NewFooter_linksMain,
  .NewFooter_info {
    flex-wrap: wrap;
  }

  .NewFooter_copyRight,
  .NewFooter_social,
  .NewFooter_socialLinks,
  .NewFooter_detailsLinks,
  .NewFooter_copyRight {
    width: 100%;
  }

  .NewFooter_MainCards {
    width: 50%;
  }

  .NewFooter_info {
    justify-content: center;
    text-align: center;
  }

  .NewFooter_MainCards {
    justify-content: left !important;
    border: none !important;
    margin-bottom: 15px;
  }

  .NewFooter_socialLinks,
  .NewFooter_detailsLinks,
  .unmetered_mob {
    justify-content: center !important;
  }

  .NewFooter_detailsLinks a {
    font-size: 10px;
    border-left: none;
    border-right: 1px solid #cccccc;
  }

  .NewFooter_Logo {
    margin: 20px 0 10px;
  }

  .NewFooter_socialLinks,
  .unmetered_mob {
    margin: 10px 0;
  }

  .NewFooter_copyRight p {
    margin-top: 15px;
  }
  .NewFooter_linksMain {
    padding: 10px;
  }
}

@media (width<=500px) {
  .NewFooter_detailsLinks a {
    font-size: 8px;
    padding: 0;
    border: 0;
  }
  .NewFooter_detailsLinks {
    gap: 10px;
  }
}
