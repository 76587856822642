.SingleHotel-activity_btn_style {
    max-width: 100%;
    border-width: .5px;
    --tw-border-opacity: 1;
    border-color: rgb(252 245 231 / var(--tw-border-opacity));
    font-weight: 600;
    margin-top: 40px;
}

.SingleHotel-act_btn {
    text-align: center;
    margin-top: -20px;
}
