.filter_your_budget_parent {
    display: flex;
    gap: 5px;
    align-items: center;
}

.filter_your_budget_parent span:nth-child(odd) {
    width: 60px;
}

.filter_your_budget input {
    width: 100%;
}

/* .filter_range_parent input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    background: red;
    cursor: pointer;
    width: 15rem;
} */
.filter_btn {
    padding: 7px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 6px;
    font-size: 12px;
}

.mobile_filter {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #fff;
    position: sticky;
    top: 108px;
    padding: 10px;
    z-index: 6;
    border-radius: 6px;
}

#mobileFilters {
    width: 100%;
    background: white;
    height: 100%;
    position: fixed;
    overflow: scroll;
    right: 0;
    top: 0;
    animation: slideIn 0.5s ease-in-out;
    z-index: 999;
}
.apply_btn .primary_btn{
    position: fixed;
    bottom: 10px;
    width: 93%;
    margin: 0 10px;
}