aside .list_details {
    background-color: transparent;
    width: 32%;
    text-align: center;
}

.icon-img{
    width: 30px;
    height: 30px;
    margin: 0 auto 5px auto;
}