.day-wise-details-headings {
  color: #016937;
  text-transform: uppercase;
  text-align: left;
}
.lineDownload {
  height: 5px;
  background-color: #016937;
  border: none;
  margin: 10px 0;
}
.day-wise-details-container {
  margin: 20px 0;
}
.package-name-duration-top {
  text-align: center;
  background-color: #e6f7ef;
  padding: 10px 5px;
  border-radius: 4px;
}
.hotel-categories_lists {
  list-style-type: none;
}

#package-details {
  display: none;
}

@media print {
  .ignore-print {
    display: none !important;
  }
  #package-details {
    display: none;
  }
}
