.basic-info-container {
  margin: 30px 20px;
}

.bg-white-div {
  background: #fff;
  border-radius: 6px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.basic-info-container select {
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  border-radius: 4px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
  padding: 7px 5px;
  font-size: 14px;
}
.basic-info-container input {
  width: 100%;
}

input::placeholder {
  font-size: 10px;
  color: #ccc;
}
.basic-info-container label {
  margin-top: 10px;
}
.radio-btn {
  border: 1px solid #b7b7b7;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 7px 2px 3px;
  font-size: 12px;
}
.property_img {
  width: 10%;
  height: 50px;
}
.property_content{
  width: 90%;
}
.property_data{
  width: 50%;
}

@media (max-width: 992px) {
  .property_img {
    width: 20%;
  }
}
@media (max-width: 768px) {
  .basic-info-container {
    margin: 20px 10px;
  }
  .property_img {
    width: 20%;
  }
  .property_content{
    width: 80%;
  }
  .property_data{
    width: 100%;
  }
}
