.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .skeleton-header,
  .skeleton-content,
  .skeleton-footer {
    background: #e0e0e0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
  }
  
  .skeleton-header {
    height: 40px;
  }
  
  .skeleton-content {
    height: 200px;
  }
  
  .skeleton-footer {
    height: 20px;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 100%;
    }
  }