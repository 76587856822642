.PkgItinerary_table {
    max-width: 767px;
    margin: 0 auto;
    background-color: #FFFFFF;
}

.PkgItinerary_logo {
    width: 100%;
    height: 100px;
    text-align: left;
}

.PkgItinerary_logo img {
    width: 100px;
    object-fit: contain;
}

.PkgItinerary_details {
    padding: 30px 30px;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    background: #e6f7ef;
    border-radius: 10px;
}

.PkgItinerary_details_heading {
    font-size: 18px;
}

.PkgItinerary_details_validity {
    text-transform: uppercase;
    font-weight: normal;
    margin-top: 3px;
    font-size: 16px;
}

.PkgItinerary_details_tourDuration {
    font-weight: 700;
    font-size: 18px;
    /* margin: 5px 0px; */
}

.PkgItinerary_wrapBox{
    margin: 30px 0px;
}

.PkgItinerary_titleHead{
        /* background: #016937; */
    /* background-color: #038848d9; */
    font-size: 18px;
    line-height: 22px;
    color: #016937;
    padding: 10px 0px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    /* border-radius: 6px; */
    border-bottom: 3px solid #016937;
}

.PkgItinerary_destinationWrap{
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0 !important;
    flex: 0 0 100%;
    max-width: 100%;
}
.PkgItinerary_imgWrapDest{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex: 0 0 32.3333333333%;
    max-width: 32.3333333333%;
    padding: 1.5px !important;
    height: fit-content;
}
.PkgItinerary_destTitle{
    padding: 8px 8px;
    display: block;
    font-size: 12px;
}
.PkgItinerary_itinearyTable tr{
    background: #e6f7ef;
    border-radius: 10px;
    overflow: hidden;
    display: block;
    margin: 15px 0px;
    padding: 25px 20px;
}
.PkgItinerary_dayBox{
    padding: 0px;
    text-align: left;
    display: block;
    text-transform: capitalize;
}
.daytxt{
    margin-right: 5px;
}
.daytxt,.dayNumber{
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    color: #291711;
}

.PkgItinerary_itinearyContent{
    padding: 10px 0px;
}

.PkgItinerary_summaryCol{
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
}
.PkgItinerary_itineary_details{
    width: 100%;
    margin: 10px 0;
}
.headerRow{
    background: #016937;
    background-color: #016937;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}
.headerRow td{
    padding: 8px 5px;
    font-size: 13px;
}

.contentRow td{
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    border: 0.5px solid #016937;
    font-size: 13px;
    line-height: 15px;
}

/* .PkgItinerary_legalWrap{
    display: flex;
    gap: 5px;
} */

.PkgItinerary_inclusion,.PkgItinerary_exclusion{
    display: inline-flex;
    width: 49.7%;
}

.PkgItinerary_legalContent{
    padding: 10px;
}
.PkgItinerary_legalContent h4{
    margin: 8px 0px;
    font-size: 14px;
}
.PkgItinerary_legalContent ul{
    margin: 5px 0px;
    padding: 0px 15px;
}
.PkgItinerary_legalContent ul li{
    list-style: square;
    line-height: 16px;
    font-size: 13px;
    padding-bottom: 5px;
}
.PkgItinerary_contactInfo{
    border-radius: 10px;
    background-color: #e6f7ef;
    color: #2a2a2a;
    padding: 30px 30px;
    margin: 50px 0px;

}
.PkgItinerary_contactInfo .PkgItinerary_name span{
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}
.PkgItinerary_contactInfo .PkgItinerary_name  h4{
    font-size: 22px;
}

.PkgItinerary_name,.PkgItinerary_c_details{
    font-size: 14px;
    line-height: 18px;
}
.PkgItinerary_c_details{
    display: flex;
    justify-content: space-between;
}
.PkgItinerary_notice{
    border-radius: 10px;
    background-color: #f7e6e6;
    color: #2a2a2a;
    font-size: 11px;
    line-height: 13px;
    padding: 30px 30px;
    margin: 50px 0px;
}

@media (width<=425px) {
    .PkgItinerary_logo{
        display: flex;
        justify-content: center;
    }
    .PkgItinerary_contactInfo{
        flex-flow: column;
    }
    .PkgItinerary_name{
        margin-bottom: 30px;
    }
}