.header-space {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.header-space a img {
  width: 90px;
  text-align: center;
}

.NewFile_bannerImg {
  height: 280px;
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 2px;
}

.NewFile_Content {
  margin-top: 20px;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.NewFile_catgoryWrap {
  /* flex: 0 0 32.3333333333%;
    max-width: 32.3333333333%; */
  width: 33.33%;
  padding: 2px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 0.5rem !important;
}
.NewFile_imageBox {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff1e5;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 380px;
}

@media (width<=992px) {
  .NewFile_imageBox {
    height: 250px;
  }
}

@media (width<=640px) {
    .NewFile_imageBox {
      height: 200px;
    }
  }

@media (width<=500px) {
  .NewFile_catgoryWrap {
    width: 50%;
  }
}
