.property_details {
  background: #fff;
  border-radius: 6px;
  width: 24.2%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.property-details-flex {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: center;
}
#propertyDetails{
  width: 32.5%;
}

@media (max-width: 1220px) {
  .property-details-flex {
    gap: 10px;
  }
  .property_details {
    width: 24%;
  }
}
@media (max-width: 1024px) {
  .property_details {
    width: 49%;
  }
  #propertyDetails{
    width: 48.5%;
  }
}
@media (max-width: 640px) {
  .property_details {
    width: 48.5%;
    height: 100px;
  }
}
@media (max-width: 370px) {
  .property_details {
    width: 100%;
    height: fit-content;
  }
  #propertyDetails{
    width: 100%;
  }
}
