.soldout_popup {
    position: relative;
    max-width: 700px;

    .new_popup_flex {
        display: flex;
        border-radius: 15px;
        overflow: hidden;
        position: relative;

        .new_popup_colored {
            background: #cff2e1;
            padding: 20px;
            width: 350px;

            .choose_us {
                background-color: transparent;
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 30px;

                .highlight {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    img {
                        height: 50px;
                        width: 50px;
                    }

                    span {
                        text-align: left;
                        font-size: 16px;
                        color: #333;
                    }
                }
            }
        }

        .new_popup_form {
            background-color: #fff;
            padding: 20px;
            width: 350px;

            .common_btn {
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: 100%;
                border-radius: 30px;
                border-width: 1px;
                --tw-border-opacity: 1;
                border-color: #eb442d;
                --tw-bg-opacity: 1;
                background-color: #eb442d;
                padding-top: 7px;
                padding-bottom: 7px;
                text-align: center;
                font-size: 12px;
                --tw-text-opacity: 1;
                color: #fff;
                transition-property: color, background-color, border-color,
                    text-decoration-color, fill, stroke, opacity, box-shadow, transform,
                    filter, -webkit-backdrop-filter;
                transition-property: color, background-color, border-color,
                    text-decoration-color, fill, stroke, opacity, box-shadow, transform,
                    filter, backdrop-filter;
                transition-property: color, background-color, border-color,
                    text-decoration-color, fill, stroke, opacity, box-shadow, transform,
                    filter, backdrop-filter, -webkit-backdrop-filter;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 300ms;

                &:hover {
                    background-color: #fff;
                    color: #eb442d;
                }
            }
        }
    }

    .soldout_popup_flex {
        @media screen and (max-width:768px) {
            flex-wrap: wrap;
            width: 100%;
        }
    }

    .soldout_close_btn {
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: -22px;
        color: white;
    }

    @media screen and (max-width:768px) {
        max-width: 90%;

        .new_popup_flex {
            .new_popup_colored {
                display: none;
            }

            .soldout_room_price {
                display: block;
                padding: 10px;
                width: 100%;
            }

            .new_popup_form {
                padding: 10px;
                width: 100%;
            }
        }
    }
}
